import * as React from "react";
import { useForm } from "@formspree/react";

const EmailSignup = () => {
  const [state, handleSubmit] = useForm("mwkaaqjr");
  if (state.succeeded) {
    return (
      <div>
        <div className="flex items-center mt-6">
          <p className="text-base text-gray-300 mr-2">Success!</p>
          <img
            className="h-6 w-6"
            src={"/green_checkmark.svg"}
            alt="Green checkmark"
          />
        </div>
        <p className="text-base text-gray-300">We appreciate your support.</p>
      </div>
    );
  }
  return (
    <form onSubmit={handleSubmit} className="mt-6 sm:flex sm:max-w-md">
      <label htmlFor="email-address" className="sr-only">
        Email address
      </label>
      <input
        type="email"
        name="email-address"
        id="email-address"
        autoComplete="email"
        required
        className="appearance-none min-w-0 w-full bg-white border border-transparent rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-400"
        placeholder="Enter your email"
      />
      <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
        <button
          type="submit"
          className="w-full bg-purple-600 border border-transparent rounded-full py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-purple-500"
          disabled={state.submitting}
        >
          Subscribe
        </button>
      </div>
    </form>
  );
};

export default EmailSignup;
