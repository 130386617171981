import * as React from "react";
import { useEffect, useState } from "react";
import { Link } from "gatsby";
import { contactBtn } from "./Header.module.css";

const navigation = [
  { name: "Services", href: "/services/" },
  { name: "Testimonials", href: "/testimonials/" },
  { name: "Blog", href: "/blog/" },
];
const Header = (props) => {
  const [lightBG, setLightBg] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (typeof window !== "undefined" && window.location.pathname === "/")
        setLightBg(window.pageYOffset > 50);
    });
  }, []);
  return (
    <header
      className={`w-full top-0 z-20 lg:py-2 ${
        props.lightHeader
          ? "sticky shadow-lg bg-white"
          : "fixed transition-all duration-200 ease-in bg-transparent"
      } ${lightBG ? "bg-white shadow-lg" : ""}`}
    >
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
        <div className="w-full flex items-center py-3 justify-between border-b border-black lg:border-none">
          <div className="flex items-center">
            <Link to="/">
              <span className="sr-only">Workflow</span>
              <img
                src={"/logo.svg"}
                alt="EnvizionLabs company logo. A scientific beaker next to text."
                height={53}
                width={250}
              />
            </Link>
            <div className="hidden ml-10 space-x-8 lg:block">
              {navigation.map((link) => (
                <Link
                  key={link.name}
                  to={link.href}
                  className={`text-base font-medium hover:text-green-600 ${
                    lightBG || props.lightHeader ? "text-black" : "text-white"
                  }`}
                >
                  {link.name}
                </Link>
              ))}
            </div>
          </div>
          <div className="ml-4 space-x-4">
            <Link
              to="/contact-us/"
              className={`inline-block py-2 px-4 border border-transparent ${contactBtn} rounded-full text-base font-medium contact-btn text-black hover:bg-opacity-75 focus:outline-none focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-green-500 whitespace-nowrap`}
            >
              Message Us
            </Link>
          </div>
        </div>
        <div className="py-4 flex flex-wrap justify-center space-x-6 lg:hidden">
          {navigation.map((link) => (
            <Link
              key={link.name}
              to={link.href}
              className="text-base font-medium text-black hover:text-green-600"
            >
              {link.name}
            </Link>
          ))}
        </div>
      </nav>
    </header>
  );
};

export default Header;
