import * as React from "react";
import Footer from "../Footer";
import Header from "../Header";
import "../../styles/global.css";
import "../../styles/typography.css";
import "@fontsource/poppins";
import "@fontsource/press-start-2p";

const Layout = ({ children }) => {
  if (children.props.location.pathname === "/") {
    return (
      <div
        style={{ display: `flex`, minHeight: `100vh`, flexDirection: `column` }}
      >
        <Header />
        <main>{children}</main>
        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{ display: `flex`, minHeight: `100vh`, flexDirection: `column` }}
    >
      <Header lightHeader />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
